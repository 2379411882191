import React from "react";
import Link from "next/link";
import "react-tabs/style/react-tabs.css";
import Meta from "../../components/Meta";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { login } from "../../redux/auth";
import { axiosIns } from "../../config/axiosIns";
import { useMutation } from "react-query";
import { useRouter } from "next/router";

const postRequest = (data) => axiosIns.post(`/truckloadMarketplaceLogin`, data);
const Login = () => {
  const dispatch = useDispatch();
  const router = useRouter();

  const { mutate, isLoading } = useMutation((values) =>
    postRequest({ ...values })
  );

  const {
    register,
    handleSubmit,
    setError,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    // console.log(data);
    mutate(data, {
      onSuccess: (data) => {
        reset({
          password: "",
          email: "",
        });
        dispatch(login(data.data));
        router.push("/profile/" + data.data._id);
      },
      onError: (error) => {
        if (error.response.status === 400 && error.response.data.errors) {
          error.response.data.errors.forEach((err) => {
            console.log(err);
            setError(err.param, {
              message: err.msg,
            });
          });
        } else setError("password", { message: error.response.data.error });
      },
    });
  };

  return (
    <div>
      <Meta title="Login || Likizone | Liquidation, Wholesale Lots and Surplus marketplace" />
      {/* <!-- Login --> */}
      <section className="relative h-screen">
        <div className="lg:flex lg:h-full">
          {/* <!-- Left --> */}
          <div className="relative text-center lg:w-1/2">
            <img
              src="/images/login.webp"
              alt="login"
              className="absolute h-full w-full object-cover"
            />
            {/* <!-- Logo --> */}
            <Link href="/">
              <a className="relative inline-block py-36">
                <img
                  src="/images/logo_white.svg"
                  className="inline-block max-h-10"
                  alt="logo image"
                />
              </a>
            </Link>
          </div>

          {/* <!-- Right --> */}
          <div className="relative flex items-center justify-center p-[10%] lg:w-1/2">
            <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
              <img
                src="/images/gradient_light.jpg"
                alt="gradient"
                className="h-full w-full"
              />
            </picture>

            <div className="w-full max-w-[25.625rem] text-center">
              <h1 className="text-jacarta-700 font-display mb-6 text-4xl dark:text-white">
                Sign in
              </h1>
              {/* <p className="dark:text-jacarta-300 mb-10 text-lg leading-normal">
                Choose one of available wallet providers or create a new wallet.
                <a href="#" className="text-accent">
                  What is a wallet?
                </a>
              </p> */}

              <div className="grid grid-cols-1 text-left">
                <div className="mb-4">
                  {" "}
                  <label
                    htmlFor="email"
                    className="font-display text-jacarta-700 mb-2 block dark:text-white"
                  >
                    Email
                  </label>
                  <input
                    type="text"
                    id="email"
                    autoComplete="false"
                    className="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white"
                    placeholder="Add your email"
                    // required
                    {...register("email", {
                      required: { value: true, message: "Email required" },
                      pattern: {
                        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                        message: "Please provide a valid email", // JS only: <p>error message</p> TS only support string
                      },
                    })}
                  />
                  {errors.email && (
                    <p className="text-red">{errors.email?.message}</p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="font-display text-jacarta-700 mb-2 block dark:text-white"
                  >
                    Password
                  </label>
                  <input
                    type="password"
                    id="password"
                    className="dark:bg-jacarta-700 border-jacarta-100 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 px-3 hover:ring-2 dark:text-white"
                    placeholder="Enter your password"
                    // required
                    {...register("password", {
                      required: {
                        value: true,
                        message: "You must specify a password",
                      },
                    })}
                  />
                  {errors.password && (
                    <p className="text-red">{errors.password?.message}</p>
                  )}
                </div>
                <div className="flex justify-center pt-5">
                  <div>
                    <button
                      onClick={handleSubmit(onSubmit)}
                      disabled={isLoading}
                      type="button"
                      className="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 mb-4 text-center font-semibold text-white transition-all"
                    >
                      Sign in
                    </button>
                  </div>
                </div>
                <p className="dark:text-jacarta-300 leading-normal">
                  Don't have an account?{" "}
                  <Link href="/create">
                    <span className="text-accent">Sign up</span>
                  </Link>
                </p>

                <Link href="/reset">
                  <span className="text-accent">Forgot password?</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- end login --> */}
    </div>
  );
};

export default Login;
